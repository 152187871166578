import React from "react";

function Team7() {
	return (
		<div className="col-sm-6 col-lg-4 col-xl-4">
			<div className="single-person">
				<div className="person-image">
					<img src="assets/images/Team Plate Tardigrde.webp" alt="" />
					<center>

					</center>
				</div>
				<div className="person-info">
				</div>
			</div>
		</div>
	)
}

export default Team7;